/** @jsx jsx */
import { selectorGlobalConfig } from "gatsby-plugin-hfn-profile/state/selectors";
import { ActionButton, Stack } from "office-ui-fabric-react";
import { useSelector } from "react-redux";
import { jsx } from "theme-ui";
import ProfileWidgetCore from "../../components/ProfileWidgetCore";
import DLWLayout from "../../layouts/dlw-mobile";

function ProfilePage() {
  const { tosUrl, privacyPolicyUrl } = useSelector(selectorGlobalConfig);

  return (
    <DLWLayout>
      <ProfileWidgetCore />
      <hr sx={{ mt: 4, mb: 1 }} />
      <Stack horizontal horizontalAlign="space-evenly">
        <ActionButton href="/" text="My Home" />
        <ActionButton href="/account/password/" text="Change Password" />
        <ActionButton
          target="_blank"
          href={tosUrl?.in}
          text="Terms of Service"
        />
        <ActionButton
          target="_blank"
          href={privacyPolicyUrl?.in}
          text="Privacy Policy"
        />
      </Stack>
    </DLWLayout>
  );
}

export default ProfilePage;
